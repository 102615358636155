import React from 'react'
import styled from 'styled-components'
import staticdata from "../../staticdata.json"

const FooterGroup = styled.div`
    background: #f1f3f5;
    padding: 100px 0;
    display: grid;
    grid-gap: 30px;
`

const Text = styled.p`
    font-size: 24px;
    font-weight: 600;
    color: #486791;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 640px) {
        font-size: 24px;
        max-width: 250px;
    }
`

const Button = styled.button`
    font-size: 24px;
    background: linear-gradient(104deg, rgb(75, 38, 255) 0%, rgb(176, 30, 255) 100%);
    box-shadow: rgba(101, 41, 255, 0.25) 0px 10px 20px;
    border-radius: 30px;
    color: white;
    border: none;
    padding: 16px 60px;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    outline: none;
    justify-self: center;
    white-space: nowrap;
    z-index: 1000;
    transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.2) 0s;

    &:hover {
    box-shadow: rgba(101, 41, 255, 0.45) 0px 20px 35px;
    transform: translateY(-5px);
    }

    @media (max-width: 640px) {
        font-size: 18px;
    }
`

const LinkGroup = styled.div`
    
    margin: 30px auto;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 40px;

    a {
        transition: 0.8s;
    }

    a:hover {
        color: black;
        transform: translateY(0px);
        box-shadow: none;
        background: none;
    }

    @media (max-width: 640px) {
        font-size: 14px;
        grid-gap: 20px;
        grid-template-columns: repeat(1, auto);
        justify-self: center;
        text-align: center;
    }
`

const Copyright = styled.div`
    color: #486791;
    max-width: 550px;
    margin: 0 auto;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
`

const Logo = styled.div`
    justify-self: center;

    img {
        width: 120px;
        margin-left: -5px;
    }

    @media (max-width: 640px) {
        img {
            max-width: 100px;
    }}
`


const Footer = ({ data, children }) => (

    <FooterGroup>
        <Logo><img src={require('../images/herki-logo.svg')} /></Logo>
        <Text><span>⚡️</span> Moderné aplikácie. V rekordnom čase.</Text>
        <Button type="button" onClick={event => window.location.href = 'mailto:info@herki.sk'}>Mám záujem</Button>
        <LinkGroup>
            {staticdata.links.map(link => (
                <a href={link.url}>{link.title}</a>
            ))}
        </LinkGroup>
        <Copyright>
            {children}
        </Copyright>
    </FooterGroup >
)

export default Footer
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./NewHeader"
import "./layout.css"
import "./Header.css"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  /*      allContentfulLink (sort: {order: ASC, fields: contentful_id}) {
          edges {
            node {
              title
              url
              contentful_id
            }
          }
        }
  */
  return (
    <>
      <div>
        <Header />
        <main>
          {children}

          <Footer data={data}>
            ©2020 HERKI s.r.o. · Všetky práva vyhradené<br />+421 910 951 163 · <a href="mailto:info@herki.sk">info@herki.sk</a>
          </Footer>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { Link } from "gatsby"
import React from "react"
import './Header.css'
import Scrollchor from 'react-scrollchor';

class NewHeader extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasScrolled: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll',
        this.handleScroll)
    }

    handleScroll = (event) => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 50) {
            this.setState({ hasScrolled: true})
        } else {
            this.setState({ hasScrolled: false})
        }
    }

    render () {
      return (
        <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
            <div className="HeaderGroup">
            <Link className="Logo" to="/"><img src={require ('../images/31-logo.svg')}/></Link>          
            <Scrollchor to="#Projekty" animate={{offset: 50}}>Projekty</Scrollchor>            
            <Scrollchor to="#Sluzby" animate={{offset: -100}}>Služby</Scrollchor>
            <a href="mailto:info@herki.sk">Kontakt</a>
            </div>
        </div>
      )
    }
}

export default NewHeader